import _ from "lodash";

export const postToForm = (post) => {
  const postInfo = {
    id: post.id,
    title: post.title,
    content: post.content,
    raw_content: post.raw_content,
    category_id: _.get(post, "category.id"),
    contact_info: post.contact_info,
    contributors: post.contributors,
    visibility: post.visibility,
    user: post.user,
  };

  _.each(post.tags, (tag) => {
    const key = `${tag.tag_group.code}_tag_ids`;

    if (!_.has(postInfo, key)) {
      postInfo[key] = [];
    }

    postInfo[key].push(tag.id);
  });

  return postInfo;
};

export const decodeTags = (tags) => {
  const result = _.chain(tags || "")
    .split("-")
    .map((id) => parseInt(id || 0))
    .value();
  if (_.every(result, (v) => v === 0)) {
    return [];
  } else {
    return result;
  }
};

export const encodeTags = (tags) => {
  const ids = _.map(tags, (id) => id || 0);
  if (_.some(ids, (id) => id !== 0)) {
    return ids.join("-");
  } else {
    return undefined;
  }
};

export const hasTags = (tags) => {
  return _.some(tags, (id) => parseInt(id) !== 0);
};
