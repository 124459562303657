// react
import { useEffect, useState } from "react";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPromos } from "~/redux/actions/app";

import _ from "lodash";
import { isMobile } from "react-device-detect";

// views
import Link from "next/link";
import { calcVaildPromoDateWithTimeZone } from "../../utils/app";

const SiderPromoList = (props) => {
  // handles
  // const handleInit = () => {
  //   props.getPromos()
  // }

  const [fixedPromos, setFixedPromos] = useState([]);
  const [randomPromos, setRandomPromos] = useState([]);

  // views
  const renderPromo = (promo) => (
    <div key={promo.id} className={`promo-item promo-type-${promo.kind}`}>
      <Link href="/promos/[id]" as={`/promos/${promo.id}`}>
        <a
          id={`promo_${promo.id}`}
          title={promo.title}
          target="_blank"
          rel={`noopener noreferrer ${promo.follow ? "" : "nofollow"}`}
        >
          <img
            src={promo.image_url}
            alt={promo.title}
            width="100%"
            height="100%"
          />
        </a>
      </Link>
    </div>
  );

  // events
  // useEffect(() => handleInit(), []);
  useEffect(() => {
    if (props.promos) {
      const promoValidator = (p) => {
        const today = new Date();
        const { startDate, endDate } = calcVaildPromoDateWithTimeZone(p);
        return p.kind === "sidebar" && today > startDate && today < endDate;
      };

      const fixedPromos = props.promos.filter(
        (p) => p.position === 0 && promoValidator(p)
      );
      setFixedPromos(fixedPromos);

      let randomPromos = props.promos.filter(
        (p) => p.position !== 0 && promoValidator(p)
      );
      if (isMobile) {
        /* 没有置顶广告时，补充一个随机显示的广告 */
        randomPromos = _.sampleSize(
          randomPromos,
          fixedPromos.length === 0 ? 3 : 2
        );
      }
      setRandomPromos(randomPromos);
    }
  }, [props.promos]);

  return (
    <div>
      {fixedPromos.map(renderPromo)}
      {randomPromos.map(renderPromo)}
      {/*<div className="wwads-cn" data-id="47" data-size="300x250"></div>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  promos: state.app.promos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPromos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiderPromoList);
