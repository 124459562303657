import { useState } from "react";
import { fetchRandomTalentPosts } from "~/redux/actions/talent";

// views
import { Card } from "antd";
import PostLink from "~/components/post/PostLink";

const TalentPostList = ({ categories, posts }) => {
  const tc = categories.find((c) => c.code === "talent");
  const [talentPosts, setTalentPosts] = useState(posts);

  const fetchTalentPosts = async () => {
    const res = await fetchRandomTalentPosts();
    setTalentPosts(res.posts);
  };

  return (
    <Card
      title="远程全职推荐"
      extra={
        <div>
          <span
            style={{ marginRight: "10px", color: "#f9ba48", cursor: "pointer" }}
            onClick={fetchTalentPosts}
          >
            刷新
          </span>
          <a style={{ color: "#f9ba48" }} href={`/categories/${tc.id}`}>
            查看全部
          </a>
        </div>
      }
    >
      <ul>
        {talentPosts &&
          talentPosts.map((post) => (
            <li key={post.id}>
              <PostLink post={post} />
            </li>
          ))}
      </ul>

      <style jsx>
        {`
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 14px;
          }

          li {
            background: #f7f7f7;
            padding: 15px 24px;
            word-wrap: break-word;
            word-break: break-all;
          }

          li + li {
            margin-top: 6px;
          }

          li a {
            display: block;
          }
        `}
      </style>
    </Card>
  );
};

export default TalentPostList;
