import { Row, Col } from 'antd'
import Link from 'next/link'

const ItemsFilter = props => {
  const Item = ({ item }) => {
    const itemId = item ? item.id : props.all
    const name = item ? item.name : '全部'
    const className = (String(itemId) === String(props.selected) || props.selected === 0 && itemId === undefined) ? 'active' : ''
    const itemUrl = props.itemUrl(itemId)

    return (
      <Link href={itemUrl.href} as={itemUrl.as}>
        <a className={className}>
          {name}

          <style jsx>{`
        // 大于常用手机屏蔽size下的样式。
        @media screen and (min-width: 600px) {
          a {
            margin-left: 10px;
            margin-right: 10px;
            white-space: nowrap;
            display: inline-block;
          }
        }

        // 常用手机屏蔽size下的样式。
        @media screen and (max-width: 600px) {
          a {
            border: 1px solid rgba(0, 0, 0, 0.12);
            font-size: 0.8rem;
            background-color: white;
            padding-top:0px;
            padding-bottom:0px;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            border-radius: 20px;
            margin-right: 10px;
            margin-bottom: 5px;
            white-space: nowrap;
            display: inline-block;
          }
        }
        `}
          </style>
        </a>
      </Link>
    )
  }

  return (
    <div className='filter text-md'>
      <Row type='flex' style={{ flexWrap: 'nowrap' }}>
        {props.name != null && (
          <Col style={{ flex: '0 0 80px' }}>
            <div className='label'>
              {props.name}:
            </div>
          </Col>
        )}
        <Col style={{ flex: 1 }}>
          <Item />
          {
            props.items.map(item => (
              <Item item={item} key={item.id} />
            ))
          }
        </Col>
      </Row>

      <style jsx>{`
        .filter {
          line-height: 1.8;
        }

        .filter + .filter {
          margin-top: 10px;
        }

        .label {
          width: 70px;
          text-align: right;
          font-weight: bold;
        }
      `}
      </style>
    </div>
  )
}

export default ItemsFilter
