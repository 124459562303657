import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAppModal } from "../../redux/actions/app";

import { FilePdfOutlined } from "@ant-design/icons";
import { Card, Progress, Button } from "antd";

import settings from "../../settings";

const TalentInfoCard = ({
  token,
  talentInfoResult,
  showAppModal,
}: {
  token: string;
  talentInfoResult: any;
  showAppModal: any;
}) => {
  const STATUSES: any = {
    employed: "在职 - 暂不考虑",
    employed_month: "在职 - 月内到岗",
    employed_watching: "在职 - 考虑机会",
    unemployed: "离职 - 随时到岗",
  };
  const { profile, progress, detailed } = talentInfoResult;
  return (
    <Card title="我的简历">
      <Progress
        style={{ width: "80%" }}
        percent={progress && progress.proportion}
        status={detailed && progress.completed ? "success" : "exception"}
        format={(percent) => `完成度${percent}%`}
      />

      {detailed && progress.completed && profile && (
        <div
          className="flex flex-direction-column mt-15"
          style={{ rowGap: "6px" }}
        >
          <div>姓名：{profile.name}</div>
          <div>求职状态：{STATUSES[profile.status]}</div>
          <div>期待薪资：{Math.floor(profile.expected_min / 1000)}k</div>
          <div>
            中文简历：
            {profile.resume_url ? (
              <FilePdfOutlined
                onClick={() => window.open(profile.resume_url, "_blank")}
              />
            ) : (
              "未上传"
            )}
          </div>
          <div>
            英文简历：
            {profile.resume_en_url ? (
              <FilePdfOutlined
                onClick={() => window.open(profile.resume_en_url, "_blank")}
              />
            ) : (
              "未上传"
            )}
          </div>
          <div>联系电话：{profile.phone.replace("+86", "")}</div>
        </div>
      )}
      <Button
        className="mt-15"
        type="primary"
        block
        onClick={() => {
          if (token) {
            window.open(`${settings.talentUrl}/me/profile`, "_blank");
          } else {
            showAppModal("PhoneSigninModal");
          }
        }}
      >
        {detailed && progress.completed
          ? "修改/查看更多"
          : "请前往人才库完善简历信息"}
      </Button>
    </Card>
  );
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
  talentInfoResult: state.talent.talentInfoResult,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TalentInfoCard);
