// react
import { useState, useEffect } from 'react'

// comps
import { Card } from 'antd'
import UserAvatar from '~/components/user/UserAvatar'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getUserSuggests } from '~/redux/actions/user'

const UserSuggestCard = props => {
  // handles
  const handleFetch = () => {
    props.getUserSuggests()
  }

  // views
  const renderUserList = (type, users) => {
    return users.map(user => (
      <UserAvatar
        user={user}
        key={`${type}:${user.id}`}
        size={40}
        style={{ marginRight: '14px' }}
      />
    ))
  }

  // events
  useEffect(() => handleFetch(), [])

  return (
    <Card title='社区居民'>
      <label>新来居民</label>
      <div style={{ marginTop: '16px', marginBottom: '20px' }}>
        {renderUserList('fresh', props.freshUsers)}
      </div>

      <label>活跃居民</label>
      <div style={{ marginTop: '16px' }}>
        {renderUserList('active', props.activeUsers)}
      </div>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  freshUsers: state.user.freshUsers,
  activeUsers: state.user.activeUsers
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserSuggests
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UserSuggestCard)
