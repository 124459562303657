// react
import { useState, useEffect } from "react";
import Image from "next/image";

// views
import { Card, Row, Col } from "antd";
import Loading from "~/components/app/Loading";
import UserAvatar from "~/components/user/UserAvatar";
import UserLink from "~/components/user/UserLink";
import Medal1Icon from "~/public/static/icons/medal-1.svg";
import Medal2Icon from "~/public/static/icons/medal-2.svg";
import Medal3Icon from "~/public/static/icons/medal-3.svg";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserRanks } from "~/redux/actions/user";

const Medals = [Medal1Icon, Medal2Icon, Medal3Icon];

const UserRanksCard = (props) => {
  // handles
  const handleFetch = () => {
    props.getUserRanks();
  };

  // views
  const renderMedal = (i) => {
    const Medal = Medals[i];
    return <Image src={Medal} width={18} height={18} />;
  };
  const renderUser = (user, i) => {
    return (
      <li key={user.id}>
        <Row type="flex" justify="space-between">
          <Col style={{ flex: "0 0 25px" }}>
            <div
              style={{
                display: "inline-block",
                lineHeight: 1,
                fontWeight: "bold",
                textAlign: "center",
                width: "18px",
                verticalAlign: "middle",
              }}
            >
              {i > 2 ? i + 1 : renderMedal(i)}
            </div>
          </Col>
          <Col style={{ flex: "1" }}>
            <div className="text-left">
              <UserLink user={user} avatar />
            </div>
          </Col>
          <Col style={{ flex: "0 0 50px", textAlign: "right" }}>{user.exp}</Col>
        </Row>
      </li>
    );
  };
  const renderList = () => (
    <ul className="sider-list">{props.ranks.map(renderUser)}</ul>
  );

  // events
  useEffect(() => handleFetch(), []);

  return (
    <Card title="居民贡献榜">{props.ranks ? renderList() : <Loading />}</Card>
  );
};

const mapStateToProps = (state) => ({
  ranks: state.user.ranks,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserRanks,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserRanksCard);
