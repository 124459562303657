// react
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card } from "antd";
import { fetchCategories, fetchSiteTagGroups } from "~/redux/actions/app";
import { fetchPostList, checkPostFlags } from "~/redux/actions/post";
import { fetchBlogrollsCategories } from "~/redux/actions/app";
import { fetchRandomTalentPosts } from "~/redux/actions/talent";
import { getUserInfo } from "~/redux/actions/user";

// views
import Link from "next/link";
import SiteLayout from "~/components/layout/SiteLayout";
import Pager from "~/components/site/Pager";
import NewPostCard from "~/components/sider/NewPostCard";
import SiderNavigationCard from "~/components/sider/SiderNavigationCard";
import UserSuggestCard from "~/components/sider/UserSuggestCard";
import UserRanksCard from "~/components/sider/UserRanksCard";
import SiderPromoList from "~/components/sider/SiderPromoList";
import WechatGroupCard from "~/components/site/WechatGroupCard";
import SiteRulesCard from "~/components/site/SiteRulesCard";
import NavsPost from "~/components/post/NavsPost";
import PostSortDropdown from "~/components/post/PostSortDropdown";
import PostListItem from "~/components/post/PostListItem";
import EmptyList from "~/components/app/EmptyList";
import Loading from "~/components/app/Loading";
import SiteTagsCard from "~/components/site/SiteTagsCard";
import SiteBanner from "~/components/site/SiteBanner";
import TalentInfoCard from "~/components/sider/TalentInfoCard";

// utils
import _ from "lodash";
import { decodeTags } from "~/utils/post";
import { loadScrollPosition, setScrollPosition } from "~/utils/ui";
import { extractDescriptionForPostList } from "../../utils/seo";
import TalentPostList from "../sider/TalentPostList";
import { getCookie } from "../../utils/cookie";
import { updateLinkQuery } from "~/utils/route";
import AppSearch from "../app/AppSearch";
import { isMobile } from "react-device-detect";
import AppMobileNav from "../app/AppMobileNav";

const PostListPage = (props) => {
  const router = useRouter();
  const query = router.query;
  const sort = query.sort || props.sort;

  // Restore scroll position
  const storeScrollToPosition = loadScrollPosition();

  if (typeof window !== "undefined" && storeScrollToPosition) {
    setTimeout(() => {
      window.scrollTo(0, storeScrollToPosition);
      setScrollPosition(0);
    }, 100);
  }

  const { postList, categories, talentPosts } = props;

  const talentVisible = categories
    ? categories.filter((c) => c.code === "talent").length > 0
    : false;

  const category = categories && categories.find((c) => c.id == query.category);

  const pageSider = (
    <div>
      <NewPostCard />
      <SiderNavigationCard />
      {props.token && category && category.code === "talent" && (
        <TalentInfoCard />
      )}
      {talentVisible && (
        <TalentPostList categories={categories} posts={talentPosts} />
      )}
      <WechatGroupCard />
      <UserSuggestCard />
      <SiteRulesCard />
      <SiderPromoList />
    </div>
  );
  const isHomePage = router.pathname === "/";

  useEffect(() => {
    if (props.sort || query.sort) {
      const link = updateLinkQuery(router, (query) => {
        query.sort = props.sort || query.sort;
      });
      router.push(link.as, undefined, { shallow: true });
    }
  }, [props.sort, query.sort]);

  // useEffect(() => {
  //   if (props.token) {
  //     props.getUserInfo(props.token);
  //     const postIds = props.postList.posts.map((post) => post.id);
  //     props.checkPostFlags(props.token, ["upvoted", "downvoted"], postIds);
  //   }
  // }, [props.postList]);
  return (
    <SiteLayout
      pageSider={pageSider}
      description={
        isHomePage ? null : extractDescriptionForPostList(postList.posts)
      }
      blogrolls={props.blogrolls}
    >
      {isMobile && <AppMobileNav />}
      <SiteBanner />

      {isMobile && <NewPostCard className="create-post-btns-wrapper" />}

      {isMobile && <AppSearch />}

      <Card>
        <NavsPost categories={props.categories} />
      </Card>

      <Card>
        <div className="mb-20" style={{ lineHeight: "30px" }}>
          <span>
            共 {postList.pager ? postList.pager.total_count : 0} 个帖子
          </span>
          <span className="pull-right">
            <PostSortDropdown localization sort={sort} />
          </span>
        </div>

        {/* 加一条横线分隔导航栏和内容区。 */}
        <div style={{ borderBottom: "1px solid #f7f7f7" }} />

        <div className={`post-list ${query.category ? "bordered" : ""}`}>
          {postList ? (
            postList.posts.length ? (
              postList.posts.map((post) => (
                <PostListItem
                  // withVote={true}
                  post={post}
                  key={post.id}
                  // upvoted={
                  //   props.flags.upvoted && props.flags.upvoted.includes(post.id)
                  // }
                  // downvoted={
                  //   props.flags.downvoted &&
                  //   props.flags.downvoted.includes(post.id)
                  // }
                />
              ))
            ) : (
              <EmptyList />
            )
          ) : (
            <Loading />
          )}
        </div>

        <Pager pager={postList && postList.pager} onPageChange={() => {}} />
      </Card>

      {/* <SiteTagsCard tagGroups={props.tagGroups} /> */}

      <style jsx>
        {`
          .post-list.bordered {
            border-top: 1px solid #f7f7f7;
          }
        `}
      </style>
    </SiteLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  flags: state.post.flags,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ checkPostFlags, getUserInfo }, dispatch);

PostListPage.requireUser = () => false;

PostListPage.getInitialProps = async (ctx) => {
  const { query } = ctx;

  const cookieSort = getCookie("sort", ctx.req);

  const postList = await fetchPostList({
    sort: query.sort || cookieSort,
    category: query.category,
    tags: decodeTags(query.tags),
    page: parseInt(query.page) || 1,
  });

  const categories = await fetchCategories();

  const { posts: talentPosts } = await fetchRandomTalentPosts();

  let tagGroups = await fetchSiteTagGroups();
  tagGroups = tagGroups.filter((t) => t.kind === "post");

  let blogrolls = [];
  if (ctx.pathname === "/") {
    const blogroll_categories = await fetchBlogrollsCategories();
    if (blogroll_categories) {
      for (let index = 0; index < blogroll_categories.length; index++) {
        const element = blogroll_categories[index];
        if (element.id === 1) {
          blogrolls = element.blogrolls;
          break;
        }
      }
    }
  }

  return {
    postList,
    categories,
    tagGroups,
    blogrolls,
    talentPosts,
    sort: cookieSort,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostListPage);
