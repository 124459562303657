import AppMenuLeft from "./AppMenuLeft";

const AppMobileNav = () => {
  return (
    <div className="app-mobile-nav">
      <AppMenuLeft mode="horizontal" />
    </div>
  );
};

export default AppMobileNav;
