// react
import { useEffect, useState } from "react";

//antd
import { Card, Badge } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { showAppModal } from "~/redux/actions/app";
import ruleIcon from "~/public/static/icons/rule.png";

// utils
import { setNavigationStorage, getNavigationStorage } from "~/utils/ui";
import settings from "../../settings";
import router from "next/router";

const SiderNavigationCard = (props) => {
  const [state, setState] = useState({
    rule: false,
    resume: false,
    about: false,
  });

  const initNavigation = () => {
    const isRuleDot = getNavigationStorage("6GzfGe");
    const isResumeDot = getNavigationStorage("talentResume");
    const isAboutDot = getNavigationStorage("MZXfoe");
    setState({
      ...state,
      rule: isRuleDot,
      resume: isResumeDot,
      about: isAboutDot,
    });
  };

  useEffect(() => {
    initNavigation();
  }, []);

  return (
    <Card className="navigation-card">
      <div className="navigation-box">
        <a
          href="https://eleduck.com/posts/6GzfGe"
          onClick={() => setNavigationStorage("6GzfGe")}
          className="navigation-item"
          target="_blank"
        >
          <img
            className="navigation-item-image"
            src={ruleIcon.src}
            alt="社区规则"
          />
          <Badge dot={state.rule}>社区规则</Badge>
        </a>
        <div className="navigation-line"></div>
        <a
          href="https://eleduck.com/posts/MZXfoe"
          onClick={() => setNavigationStorage("MZXfoe")}
          className="navigation-item"
          target="_blank"
        >
          <InfoCircleOutlined
            style={{ color: "#f9ba48", fontSize: "33px", padding: "8px 0" }}
            alt="关于电鸭"
          />
          <Badge dot={state.about}>关于电鸭</Badge>
        </a>
        {/* <a
          href="https://eleduck.com/posts/6yGflz"
          onClick={() => setNavigationStorage("6yGflz")}
          className="navigation-item"
        >
          <img
            className="navigation-item-image"
            src={"/static/icons/construction.png"}
            alt="加入我们"
          />
          <Badge dot={state.construction}>加入我们</Badge>
        </a> */}
        <div className="navigation-line"></div>
        <div
          onClick={() => {
            if (props.token) {
              window.open(`${settings.talentUrl}/me/profile`, "_blank");
              setNavigationStorage("talentResume");
            } else {
              props.showAppModal("PhoneSigninModal");
            }
          }}
          className="navigation-item"
          style={{ cursor: "pointer" }}
        >
          <FilePdfOutlined
            style={{ color: "#f9ba48", fontSize: "33px", padding: "8px 0" }}
            alt="我的简历"
          />
          <Badge dot={state.resume}>我的简历</Badge>
        </div>
      </div>
      <style jsx>
        {`
          .navigation-card .ant-card-body {
            padding: 16px 22px;
          }

          .navigation-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .navigation-item {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .navigation-item-image {
            width: 49px;
            height: 48rpx;
          }

          .navigation-line {
            width: 0px;
            height: 32px;
            border: 1px solid #f2f2f2;
          }
        `}
      </style>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiderNavigationCard);
