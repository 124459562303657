// react
import { useRouter } from 'next/router'
import ItemsFilter from '~/components/site/ItemsFilter'

// utils
import _ from 'lodash'
import { decodeTags, encodeTags } from '~/utils/post'
import { buildCategoryUrl } from '~/utils/route'
import Head from 'next/head';
import { extractCategoryTitle, generateTitleWithSuffix } from '../../utils/seo';

const NavsPost = props => {
  const router = useRouter()

  const tags = decodeTags(router.query.tags)
  let categoryId = parseInt(router.query.category)
  const category = _.find(props.categories, { id: categoryId })

  const buildTagUrl = (index, tagId) => {
    const tagsCopy = _.clone(tags)
    tagsCopy[index] = tagId

    const tagsSegment = encodeTags(tagsCopy)

    if (category) {
      if (tagsSegment) {
        return {
          href: '/categories/[category]/tags/[tags]',
          as: `/categories/${category.id}/tags/${tagsSegment}`
        }
      } else {
        return buildCategoryUrl(category.id)
      }
    } else {
      if (tagsSegment) {
        return {
          href: '/tags/[tags]',
          as: `/tags/${tagsSegment}`
        }
      } else {
        return {
          href: '/',
          as: '/'
        }
      }
    }
  }

  // views
  const renderTagsFilter = (tagGroup, index) => {
    return (
      <ItemsFilter
        all={undefined}
        key={tagGroup.code}
        name={tagGroup.name}
        items={tagGroup.tags}
        selected={parseInt(tags[index] || 0)}
        itemUrl={tagId => buildTagUrl(index, tagId)}
      />
    )
  }

  return (
    <div className='navs-post'>
      <Head>
        <title key={"title"}>{generateTitleWithSuffix(extractCategoryTitle(props.categories, categoryId, tags))}</title>
      </Head>

      <ItemsFilter
        all={undefined}
        items={props.categories}
        selected={category && category.id}
        itemUrl={buildCategoryUrl}
      />
      {category && category.tag_groups.map(renderTagsFilter)}
    </div>
  )
}

export default NavsPost
