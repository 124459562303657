import _ from "lodash";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { EffectFade, Autoplay, Pagination } from "swiper";
import { isMobile } from "react-device-detect";
import Link from "next/link";

import { getPromos } from "../../redux/actions/app";
import { calcVaildPromoDateWithTimeZone } from "../../utils/app";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const SiteBanner = ({
  getPromos,
  promos,
}: {
  getPromos: () => void;
  promos: any[];
}) => {
  const [bannerPromos, setBannerPromos] = useState<any[]>([]);

  // useEffect(() => getPromos(), []);

  useEffect(() => {
    if (!promos) return;
    const promoValidator = (p: any) => {
      const today = new Date();
      const { startDate, endDate } = calcVaildPromoDateWithTimeZone(p);
      return p.kind === "banner" && today > startDate && today < endDate;
    };

    setBannerPromos(_.shuffle(promos.filter((p: any) => promoValidator(p))));
  }, [promos]);

  return (
    <>
      {bannerPromos && (
        <div className="promo-banner">
          <Swiper
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            allowTouchMove={false}
            modules={[EffectFade, Autoplay, Pagination]}
          >
            {bannerPromos.map((promo: any) => (
              <SwiperSlide key={promo.id}>
                <Link href="/promos/[id]" as={`/promos/${promo.id}`}>
                  <a
                    id={`promo_${promo.id}`}
                    title={promo.title}
                    target="_blank"
                    rel={`noopener noreferrer ${
                      promo.follow ? "" : "nofollow"
                    }`}
                  >
                    <img
                      src={
                        isMobile
                          ? promo.image_500x120_url
                          : promo.image_800x100_url
                      }
                      alt={promo.title}
                    />
                  </a>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({ promos: state.app.promos });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ getPromos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SiteBanner);
