import { Card } from "antd";
import listIcon from "~public/static/icons/list.png";

export default (props) => (
  <Card title="社区潜规则">
    <ul className="text-secondary">
      <li>非传统坐班的工作机会，会被社区优先展示</li>
      <li>与工作或职场相关的话题，在这里更受欢迎</li>
      <li>不舍得花电量联系或申请，大概率是不值得</li>
      <li>"顶/踩"决定排序，"电一下"表达同意或感谢</li>
      <li>作为练习，更鼓励大家多用英文发帖或评论</li>
    </ul>

    <style jsx>
      {`
        ul {
          margin: 0;
          padding: 0;
          font-size: 13px;
          color: #9b9b9b;
          list-style-type: none;
        }

        li {
          background: url(${listIcon.src}) left center no-repeat;
          padding-left: 20px;
          line-height: 25px;
        }
      `}
    </style>
  </Card>
);
