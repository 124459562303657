// views
import Link from "next/link";

// utils
import { postUrl, getHref } from "~/utils/route";
import { truncate } from "~/utils/app";

export default (props) => {
  const title = props.altTitle ? truncate(props.post.title) : props.post.title;

  return (
    <Link href={getHref(props.post)} as={postUrl(props.post)}>
      <a title={props.post.title} target="_blank">
        {props.isText ? (
          <span style={{ color: "rgba(0, 0, 0, 0.85)", ...props.style }}>
            {title}
          </span>
        ) : (
          <h3
            style={{ fontWeight: 400, fontSize: "14px", marginBottom: "0px" }}
          >
            {title}
          </h3>
        )}
      </a>
    </Link>
  );
};
